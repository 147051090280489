<template>
  <div class="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-p-6 tw-drop-shadow">
    <div class="tw-text-left">
      <p class="tw-mb-6 tw-mt-1 tw-text-gray-600">
        We're here to help Mon to Fri, 8am to 8pm eastern time.
      </p>
    </div>

    <a
      :href="`tel:+${branding.outreachNumber}`"
      class="tw-no-underline"
      data-testid="call-us"
      @click="$analytics.trackElementClicked({ elementType: 'icon', text: 'call us' })"
    >
      <div v-if="branding.outreachNumber" class="tw-my-4">
        <div class="tw-mb-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div>
            <h4 class="tw-pb-2 tw-font-semibold tw-text-gray-800">Call us</h4>

            <span class="tw-font-normal tw-text-gray-600">
              {{ formatPhoneNumber(branding.outreachNumber) }}
            </span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-end tw-rounded-full tw-bg-gray-100 tw-p-3">
            <PhoneIcon class="tw-w tw-h-5 tw-w-5 tw-text-gray-900" />
          </div>
        </div>
        <div class="tw-border-b tw-border-gray-200" />
      </div>
    </a>
    <a
      v-if="branding.supportEmail"
      :href="`mailto:${branding.supportEmail}`"
      class="tw-no-underline"
      data-testid="email-us"
      @click="$analytics.trackElementClicked({ elementType: 'icon', text: 'email us' })"
    >
      <div class="tw-my-4">
        <div class="tw-mb-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div>
            <h4 class="tw-pb-2 tw-font-semibold tw-text-gray-800">Email us</h4>

            <span class="tw-break-all tw-font-normal tw-text-gray-600">
              {{ branding.supportEmail }}
            </span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-end tw-rounded-full tw-bg-gray-100 tw-p-3">
            <EmailIcon class="tw-w tw-h-5 tw-w-5 tw-text-gray-900" />
          </div>
        </div>
        <div class="tw-border-b tw-border-gray-200" />
      </div>
    </a>
    <div v-if="branding.supportFaxNumber" class="tw-my-4" data-testid="fax-us">
      <div class="tw-mb-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div>
          <h4 class="tw-pb-2 tw-font-semibold tw-text-gray-800">Fax</h4>

          <span class="tw-font-normal tw-text-gray-600">
            {{ formatPhoneNumber(branding.supportFaxNumber) }}
          </span>
        </div>
      </div>
      <div class="tw-border-b tw-border-gray-200" />
    </div>

    <div v-if="branding.address1" class="tw-my-4">
      <div class="tw-mb-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
        <div>
          <h4 class="tw-pb-2 tw-font-semibold tw-text-gray-800">Physical address</h4>

          <div class="tw-grid tw-gap-1">
            <span class="tw-font-normal tw-text-gray-600">
              {{ branding.address1 }} {{ branding?.address2 }}
            </span>
            <span class="tw-font-normal tw-text-gray-600">
              {{ branding?.city }}, {{ branding?.state }}
              {{ branding?.zipCode }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <h2 class="tw-py-4 tw-text-2xl tw-font-bold tw-text-gray-800">FAQ</h2>
    <AutoAccidentFAQ v-if="isAutoAccident" />
    <MassTortFAQ v-else />
  </div>
</template>

<script setup lang="ts">
import { formatPhoneNumber } from '~/utils/phone-numbers'
import EmailIcon from '~icons/heroicons-solid/envelope'
import PhoneIcon from '~icons/heroicons-solid/phone'

const { $auth } = useNuxtApp()

const branding = useBrand()

const $analytics = useAnalytics()

const isAutoAccident = computed(() => $auth?.user?.lead?.matterType === 'Auto Accident')

defineEmits(['onClose'])
</script>
