<template>
  <Disclosure v-for="(faq, index) in autoAccidentFaqs" v-slot="{ open }" :key="index">
    <DisclosureButton
      class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
      data-testid="faq-question"
      @click="
        $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
      "
    >
      <span v-text="faq.question" />
      <DownCaretIcon
        class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
        :class="open ? 'tw-rotate-180' : ''"
      />
    </DisclosureButton>
    <DisclosurePanel
      class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
    >
      <p v-for="(paragraph, pIndex) of faq.answer" :key="pIndex" v-text="paragraph" />
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import DownCaretIcon from '~icons/heroicons-solid/chevron-down'

const { $analytics } = useNuxtApp()

const autoAccidentFaqs = [
  {
    question: 'How do you keep my information secure?',
    answer: [
      'We use HIPAA-compliant authentication, and your sensitive data is encrypted in flight and at rest.',
    ],
  },
  {
    question: 'How long does it take for a personal injury claim to settle?',
    answer: [
      'The time it takes to resolve a personal injury claim depends on the specific facts of the case.',
      'While cases can occasionally be settled quickly, others can last for several years. Different types of injuries have different levels of complexity, which will impact how long it takes for the attorneys and courts to arrive at an outcome.',
    ],
  },
  {
    question: 'How proper documentation helps with your claim.',
    answer: [
      'Evidence can be key to winning legal battles in the courtroom. Evidence is anything you can use to prove the facts of a case. It can include physical elements gathered at the scene of an accident - for example, skid marks left on pavement, video footage from traffic cameras, photographs taken by passers-by, or the results of breathalyzer tests administered by law enforcement officers. But evidence might also consist of eyewitness statements, voice recordings, even visible damage to vehicles or medical reports describing injuries sustained by accident victims.',
      `Evidence can also extend to courtroom testimony from expert witnesses, such as brain surgeons, traffic control engineers, accountants and financial advisors - or even such obscure and hard-to-find specialists as accident reconstructionist. Use common sense when posting anything on social media sites. Likewise, you'd be unwise to send text messages or emails describing your condition even to friends who've asked about your circumstances.`,
      `All forms of evidence must be protected and handled appropriately so their chain of custody is unassailable. As experienced personal injury attorneys, we understand how to effectively protect evidence to make certain its authenticity and veracity can't be questioned. We also do whatever is needed to preserve and back up evidence so that nothing will be inadvertently misplaced or become subject to doubt when it's ultimately presented during legal proceedings.`,
    ],
  },
  {
    question: 'How do I obtain an accident report from the police?',
    answer: [
      `Police reports contain details about the circumstances of a car accident that can be useful for proving fault in a lawsuit. Individuals can typically purchase these reports directly from the law enforcement agency that responded to the incident. This can often be done online through the agency's website, by mail, or in person at their office. In some cases, third-party vendors may also offer these reports for a fee.`,
      'As these reports require a high level of documentation, it can take several days for them to be completed and made available. It is important that you obtain a police report as soon as possible to ensure that it is accurate, complete, and detailed enough to be of use in your case. Once you have the report, be sure to get a copy to your car accident lawyer so that they have the access to resources they need to build your case.',
    ],
  },
  {
    question: 'How should I deal with an insurance adjuster after a car accident?',
    answer: [
      'When an insurance adjuster contacts you, make sure you clarify what company they represent. Talking to your own no-fault provider or a property damage adjuster is usually fine, but you should never speak with the insurance adjuster of the at-fault driver.',
      'They are simply looking for a way out of paying a settlement and may use your words against you if you speak with them. If you war not sure who you are talking to or are uncomfortable with the questions you are asking, you are always best off talking to an attorney before answering questions.',
    ],
  },
]
</script>
