<template>
  <div>
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>Who is Torticity?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          Torticity is a litigation support service that is hired by the law firm to obtain medical
          records, complete discovery documents, and gather any other documents that are needed in
          order to build a strong claim. Once all the necessary documents are obtained relating to
          your exposure and injuries, we send this information for the law firm to review and they
          handle the litigation/court side of your claim.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>What is a mass tort lawsuit? Is it the same as a class action lawsuit?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          In a class action, everyone is combined and the potential payout is usually the same for
          each claimant. In a Mass Tort claim/MDL everyone is looked at on an individual basis based
          on the medical records and the injuries sustained.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>How can my family members also set up a claim for themselves?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          At Torticity, we are unable to set up new claims on behalf of the law firm, but if you
          contact them directly they will be more than happy to screen any new individuals to see if
          they will qualify for the lawsuit.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>Where can I send my records?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p v-if="branding.supportEmail">
          Email:
          <a class="tw-text-blue-700 hover:tw-underline" href="mailto:{{ branding.supportEmail }}">
            {{ branding.supportEmail }}
          </a>
        </p>
        <p v-if="branding.outreachNumber">SMS: {{ formatPhoneNumber(branding.outreachNumber) }}</p>
        <p v-if="branding.supportFaxNumber">
          Fax: {{ formatPhoneNumber(branding.supportFaxNumber) }}
        </p>
        <p />
        <div v-if="branding.lawFirm?.name" class="tw-grid tw-gap-1">
          <span class="tw-font-normal tw-text-gray-600">
            Mail: {{ branding.lawFirm?.name }} c/o Torticity, LLC 7700 Griffin Way Suite B.
          </span>
          <span class="tw-font-normal tw-text-gray-600">Willowbrook, IL 60527</span>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>Why is Torticity getting my medical records and not the Law Firm?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          Torticity has been hired by the law firm to obtain all documents necessary to present your
          claim to the court. We will work with your closely to ensure all the documents they need
          are obtained by the facilities or provided by you to make the strongest claim possible.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>What is the timeline for me to receive any compensation for my claim?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          Unfortunately, due to the nature of these claims there is no way for us to give a
          timeline. Mass torts such as these, do take quite a long time, which usually equates to
          years not months.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>Can I take out a loan before my case settles?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          This is something you would need to speak to your law firm directly about, as we do not
          have anything to do with pre-settlement funding.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>What is an affidavit?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          An affidavit is a sworn statement put in writing. This is a legal document used to claim
          certain information is true and correct to the best of the person's knowledge. This is
          like taking an oath in court.
        </p>
      </DisclosurePanel>
    </Disclosure>

    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="tw-gray-200 tw-flex tw-w-full tw-justify-between tw-border-b tw-py-6 tw-text-left tw-font-semibold tw-text-gray-800 last:tw-border-0"
        data-testid="faq-question"
        @click="
          $analytics.trackElementClicked({ elementType: 'drawer', text: $event.target.textContent })
        "
      >
        <span>How do you keep my information secure?</span>
        <DownCaretIcon
          class="tw-ml-auto tw-h-5 tw-w-5 tw-flex-shrink-0 tw-transform-gpu tw-text-sm tw-text-gray-800 tw-transition"
          :class="open ? 'tw-rotate-180' : ''"
        />
      </DisclosureButton>
      <DisclosurePanel
        class="tw-gray-200 tw-space-y-6 tw-border-b tw-py-6 tw-font-normal tw-text-gray-600 last:tw-border-0"
      >
        <p>
          We use HIPAA-compliant authentication, and your sensitive data is encrypted in flight and
          at rest.
        </p>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import { formatPhoneNumber } from '~/utils/phone-numbers'
import DownCaretIcon from '~icons/heroicons-solid/chevron-down'

const { $analytics } = useNuxtApp()

const branding = useBrand()
</script>
